<script setup lang="ts">
import { useI18n } from "vue-i18n";

const route = useRoute();
const to = route.query.to as string;
const params = { to: to };

defineOptions({
  name: "AccountLayout",
});

useAuthGuardRedirection(params);

const sections = [
  { path: "/account", title: "account.menu.accountOverviewHeader" },
  { path: "/account/profile", title: "account.yourProfile" },
  { path: "/account/address", title: "account.yourAddress" },
  { path: "/account/payment", title: "account.myPaymentsHeader" },
  { path: "/account/order", title: "account.orderHistoryHeader" },
];

// Navigation for Account page
const { loadNavigationElements } = useNavigation();
const { logout, user, isLoggedIn } = useUser();
const router = useRouter();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { data } = await useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 2 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = await useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 2 });
});

const { loadNavigationElements: loadTopNavigationElements } = useNavigation({
  type: "service-navigation",
});
const { data: topNavigationData } = useAsyncData(
  "mainServiceNavigation",
  () => {
    return loadTopNavigationElements({ depth: 1 });
  }
);
provide("swNavigation-service-navigation", topNavigationData);

const isUnmounting = ref(false);

onBeforeUnmount(() => {
  isUnmounting.value = true;
});

async function invokeLogout() {
  try {
    await logout();
    // Wait for next tick to ensure clean component unmounting
    await nextTick();
    // Use replace instead of push to avoid navigation stack issues
    await router.replace("/");
  } catch (error) {
    console.error("Logout error:", error);
  }
}

provide("swNavigation-footer-navigation", footerData);

const helloAgainPoints = ref(0);

const performHelloAgainPoints = async () => {
  if (isUnmounting.value) return;

  try {
    const res = await $fetch(
      "https://api.helloagain.at/external/api/v1/users/?email=" +
        user.value?.email,
      {
        method: "GET",
        headers: {
          "helloagain-api-key": "5572d731-518c-4028-90f8-49d90c116319",
        },
      }
    );

    if (!isUnmounting.value) {
      helloAgainPoints.value = 1;
    }
  } catch (error) {
    console.error("Error fetching hello again points:", error);
  }
};

performHelloAgainPoints();

const { locale } = useI18n();

const isActiveRoute = (path: string) => {
  const localePrefix = `/${locale.value}`;
  return (
    router.currentRoute.value.path === localePrefix + path ||
    router.currentRoute.value.path === path
  );
};

const gotoPage = (page: string) => {
  router.push(formatLink(page));
};
</script>
<template>
  <div class="account-layout-wrapper mt-[60px] md:mt-[85px]">
    <LayoutHeader />
    <LayoutNotifications />
    <div class="account-breadcrumb">
      <div class="max-w-auto xl:max-w-[1190px] !mx-auto px-4 relative">
        <LayoutBreadcrumbs />
      </div>
    </div>
    <main class="mt-8 account-page">
      <section class="prose-p:text-sm">
        <div class="container">
          <div class="text-center mb-14">
            <h1 class="font-bold text-2xl lg:text-4xl mb-7 text-center">
              {{ $t("account.accountOverviewHeader") }}, {{ user?.firstName }}
            </h1>
            <p>
              {{ $t("account.welcomeInfo") }}
            </p>
          </div>
          <div class="relative flex flex-wrap">
            <!-- Sidebar Navigation -->
            <aside class="hidden laptop:block md:w-1/4 pr-4 laptop:pr-8">
              <ul
                class="[&>li:not(.active)>button]:bg-[transparent] [&>li.active>button]:!text-skin-white [&>li.active>button]:!bg-skin-theme [&>li>button]:text-skin-secondary [&>li>button]:border-[transparent] ![&>li.active>button]:text-skin-white prose-li:border-b prose-li:border-skin-primary [&>li.active]:py-5"
              >
                <li
                  v-for="section in sections"
                  :key="section.path"
                  :class="{ active: isActiveRoute(section.path) }"
                >
                  <button
                    class="btn-link w-full"
                    @click="gotoPage(section.path)"
                  >
                    {{ $t(section.title) }}
                  </button>
                </li>
                <li>
                  <button class="btn-link w-full" @click="invokeLogout()">
                    {{ $t("account.logout") }}
                  </button>
                </li>
              </ul>
            </aside>

            <!-- Content Sections -->
            <div class="w-full laptop:w-3/4 laptop:pl-[7.5rem] md:-mt-5">
              <div v-for="section in sections" :key="section.path">
                <div
                  class="border-2 border-skin-primary laptop:border-0 rounded laptop:[&:not(.active)]:hidden mb-5 laptop:mb-0 [&.active>button]:bg-skin-theme [&.active>button]:text-skin-white [&.active>[data-tab]]:!block"
                  :class="{ active: isActiveRoute(section.path) }"
                >
                  <button
                    type="button"
                    class="w-full text-left p-5 text-lg font-bold laptop:hidden"
                    @click="gotoPage(section.path)"
                  >
                    {{ $t(section.title) }}
                  </button>
                  <div
                    class="p-5 laptop:p-0 laptop:!block"
                    v-if="isActiveRoute(section.path)"
                  >
                    <slot />
                  </div>
                </div>

                <!-- Logout Button -->
              </div>
              <div
                class="border-2 border-skin-primary laptop:border-0 rounded mb-5"
              >
                <button
                  type="button"
                  class="w-full text-left p-5 text-lg font-bold laptop:hidden"
                  @click="invokeLogout()"
                >
                  {{ $t("account.logout") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <LayoutFooter />
  </div>
</template>
