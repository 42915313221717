/**
 * Guard for checking if the user is authenticated.
 * The check is done on the client side only. If the user is not logged in, the user is redirected to the homepage.
 * If the user is not logged in and a destination is provided, the user is redirected to the destination.
 */
export function useAuthGuardRedirection(params?: { to: string }) {
  const { isLoggedIn } = useUser();
  const router = useRouter();
  const { pushInfo } = useNotifications();
  const localePath = useLocalePath();
  const { formatLink } = useInternationalization(localePath);
  const { t } = useI18n();

  watch(
    isLoggedIn,
    (isLoggedIn) => {
      if (import.meta.client && !isLoggedIn) {
        if (!params?.to) {
          router.push(formatLink("/"));
          pushInfo(t("logoutMessage"));
        }
        if (params?.to) {
          router.push(formatLink(params.to));
          pushInfo(t("redirectMessage", { to: params.to }));
        }
      }
    },
    {
      immediate: true,
    }
  );
}
